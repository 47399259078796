<template>
  <base-section id="about-us" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row>
        <v-col cols="12">
          <v-card tile flat>
            <v-card-title>{{ $t("privacyPolicyTitle") }}</v-card-title>
            <v-card-text>
              <i18n
                path="privacyPolicy"
                tag="div"
                style="white-space: pre-wrap"
              >
                <template v-slot:mail>
                  <a href="mailto: info@foodeo.es">info@foodeo.es</a>
                </template>
                <template v-slot:company-name>
                  Darling Paul Padrón Díaz
                </template>
                <template v-slot:cif> 11110010K </template>
                <template v-slot:address>
                  Las Puntas Casita 5, 38911 Frontera - Santa Cruz de Tenerife
                </template>
                <template v-slot:phone> +34 960 25 48 43 </template>
              </i18n>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "PrivacyPolicySection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    description() {
      return this.$route.meta.description;
    },
  },
};
</script>
